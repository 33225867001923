@import '/src/styles/base/mixin.scss';
@import '/src/styles/fonts/fonts.scss';
@import '/src/styles/base/variables.scss';

.nameLogo{
    display: flex;
    flex-direction: row;
}

.nav {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #6e6c6c;
    background-color: #1E201E;
}

.logo {
    border-left: 2px solid #6e6c6c;
    padding-left: 5px;
}

.nav_content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    color: white;
}

.nav_centreButtons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    div, img{
    padding: 5px;
    margin: 0px 10px 0px 0px;
    &:hover{
        cursor: pointer;
    }
    }
    img{
        margin: 5px;
        height: 10px;
        width: 10px;
        &:hover{
            cursor: pointer;
        }
    }
}

.nav_centreLogo{
    div{
    margin: 0px 10px;
    padding: 5px;
    }
}

.say_hello{
    animation: shake 5s infinite forwards;
    box-shadow: 0 4px 8px 0 rgba(253, 253, 253, 0.2), 0 6px 20px 0 rgba(44, 42, 42, 0.19);
}
